export const TRANSLATIONS_AR = {
  Home: "الصفحة الرئيسية",
  CourseLibrary: "مكتبة الدورة",
  Profile: "حساب تعريفي",
  YourAssignment: "مهمتك",
  Certificates: "الشهادات",
  UserReports: "تقارير المستخدم",
  SearchCourse: "دورة البحث",
  viewAll: "مشاهدة الكل",
  GenerateReport: "انشاء تقرير",
  Enroll: "يتسجل، يلتحق",
  CourseContent: "محتوى الدورة",
  Material: "المواد",
  Overview: "ملخص",
  MyProgress: "تقدمي",
  Aboutlession: "حول النفاق",
  quiz: "اختبار",
  ExternalTraining: "التدريب الخارجي",
  InternalTraining: "تدريب داخلي",
  DASHBOARD: "لوحة القيادة",
  MyCourses: "دوراتي",
  FirstAid: "إسعافات أولية",
  Description: "وصف الدورات",
  Start: "يبدأ",
  HeatStress: "الإجهاد الحراري",
  AddCertificate: "أضف شهادة",
  PreviousTraining: "تدريب خاص",
  CourseName: "اسم الدورة التدريبية",
  MainMenu: "القائمة الرئيسية",
  home: "الصفحة الرئيسية",
  Training: "تمرين",
  Dashboard: "لوحة القيادة",
  LMSCourseBuilder: "منشئ دورة LMS",
  Groups: "مجموعات",
  GroupEnrollments: "تسجيلات المجموعة",
  Users: "المستخدمون",
  InternalOfflineTraining: "التدريب الداخلي دون اتصال",
  CourseReports: "التقارير",
  Enrollments: "التسجيلات",
  SubmittedAssignment: "الواجب المقدم",
  Settings: "إعدادات",
  TrainingEnrollments: "التسجيلات التدريبية",
  CompanyName: "اسم الشركة",
  GroupName: "أسم المجموعة",
  FromDate: "من التاريخ",
  ToDate: "حتى تاريخه",
  SelectCompany: "حدد شركة",
  SelectGroup: "اختر مجموعة",
  SelectCourseName: "حدد اسم المقرر الدراسي",
  SelectDate: "حدد تاريخ",
  UserView: "عرض المستخدم",
  Filter: "منقي",
  OverdueEnrollments: "التسجيلات المتأخرة",
  NumberOfOverdueEnrollments: "عدد التسجيلات المتأخرة",
  ViewAll: "مشاهدة الكل",
  UserPassedInThisMonth: "مر المستخدم هذا الشهر",
  UserFailedInThisMonth: "فشل المستخدم هذا الشهر",
  NumberOfUserPassed:"تجاوز المستخدم هذا الشهر",
  NumberOfUserFailed: "عدد المستخدمين الذين فشلوا",
  NewEmployees: "موظفين جدد",
  NumberOfNewEmployes: "عدد الموظفين الجدد",
  NumberOfTrainingCertificates:"عدد الشهادات التدريبية",
  ExpiringTrainingCertificates: "انتهاء صلاحية شهادات التدريب",
  Reports: "تقارير",
  Date: "تاريخ",
  SendReminder: "يرسل تذكير",
  Show :"يعرض",
  PagePerItem:"صفحة لكل عنصر",
  RefNo:"مصدر رقم",
TrainingProvider:  "مزود التدريب",
Back:"خلف",
StartDate:"تاريخ البدء",
CompletionDate:"موعد الإكمال",
DueOn:"تاريخ الاستحقاق",
Status:"حالة",
Action:"عمل",
Name:"اسم",
Company:"اسم",
Department:"اسم",
JobTitle:"مسمى وظيفي",
JoiningDate:"تاريخ الانضمام",
EmployeeNo:"رقم الموظف",
Email:"البريد الإلكتروني",
Role:"دور",
Statistics:"إحصائيات",
NewEmployeesDetails:"تفاصيل الموظف الجديد",
EmployeeID:"هوية الموظف",
EmployeeName:"اسم الموظف",
TrainingProvider:"مزود التدريب",
CertificateName:"اسم الشهادة",
Status:"حالة",
Search:"يبحث",
ListOfCertificate:"قائمة الشهادات",
TypeOfTraining:"نوع من التدريب",
IssuedOn:"الصادر في",
Expiry:"انقضاء",
Download:"تحميل",
Remind:"يتذكر",
Review:"إعادة النظر",
Retake:"إعادة",
AddNewNewGroup:"أضف مجموعة جديدة",
GroupList:"قائمة المجموعة",
GroupNo:"رقم المجموعة",
Name:"اسم",
NumberOfMember:"عدد الاعضاء",
NoOfCourse:"رقم بالطبع",
CreatedOn:"تم إنشاؤها على",
Action:"عمل",
Edit:"يحرر",
Archive:"أرشيف",
SRNo:"الأب رقم",
AddNewGroupEditGroup:"إضافة مجموعة جديدة / تحرير المجموعة",
GroupName:"أسم المجموعة",
GroupDescription:"وصف المجموعة",
CreatedBy:"انشأ من قبل",
ListOfGroupMember:"قائمة أعضاء المجموعة",
Remove:"إزالة",
EmailID:"عنوان الايميل",
ListOfAllUser:"قائمة بكافة المستخدمين",
AddInGroup:"أضف في المجموعة",
SaveUpdate:"حفظ وتحديث",
AddGroup:"أضف مجموعة جديدة",
UserList:"قائمة المستخدمين",
AddNewUser:"إضافة مستخدم جديد",
FullName:"الاسم الكامل",
Cancel:"يلغي",
Save:"يحفظ",
Instructor:"معلم",
Admin:"مسؤل",
User:"المستعمل",
AdminIns:"من يمكنه إضافة مستخدمين جدد وإعادة تعيين كلمة المرور وتغيير عنوان البريد الإلكتروني والإضافة إلى المجموعات.",
UserIns:"يمكن لمن يستهلك المادة التدريبية إنشاء تقرير عن حالة التدريب الخاصة به وتحميل التدريب السابق الذي أنهيه.",
InstructorIns:"يقوم بإضافة دورات تدريبية جديدة أو تحريرها أو حذفها ، ويقوم بإنشاء تقييم وتخصيصها للمستخدمين",
View:"رأي",
UserName:"اسم االمستخدم",
EmployeeNumber:"رقم الموظف",
FirstName:"الاسم الاول",
LastName:"الكنية",
JoiningDate:"تاريخ الانضمام",
InternalLMSTraining:"تدريب داخلي على LMS",
ExternalTraining:"التدريب الخارجي",
UserDetailsof:"تفاصيل المستخدم الخاصة بـ",
GetCertificate:"احصل على الشهادة",
PreviousTrainingCertificates:"شهادات التدريب السابقة",
Add:"يضيف",
CertificateExpiry:"انتهاء الشهادة",
EmployeeEmailID:"معرف البريد الإلكتروني للموظف",
CourseTitle:"عنوان الدورة",
RefrenceNumber:"رقم المرجع",
CourseProvider:"موفر الدروس",
DateOfIssue:"تاريخ المسألة",
ExpiryDate:"تاريخ انتهاء الصلاحية",
Upload:"تحميل",
Delete:"حذف",
Submit:"يُقدِّم",
UploadCertificate:"تحميل الشهادة",
Group:"مجموعة",
ListOfGroupEnrollment:"قائمة تسجيل المجموعة",
AddNewGroupEnrollment:"إضافة تسجيل مجموعة جديدة",
DueDate:"تاريخ الاستحقاق",
EnrollmentDate:"تاريخ التسجيل",
TypeOftraining:"نوع من التدريب",
AddNewEditGroupEnrollments:"إضافة جديدة تحرير تسجيلات المجموعة",
SelectTrainingCourse:"حدد دورة تدريبية",
Update:"تحديث",
Members:"أعضاء",
IndividualDueDatesOfUsers:"تواريخ الاستحقاق الفردية للمستخدمين",
Generate:"يولد",
Completed:"مكتمل",
ExtraOptionsforInternalOfflineandExternalTrainings:"خيارات إضافية للتدريب الداخلي والخارجي",
NotificationFrequency:"تردد الإخطار",
No:"رقم",
Yes:"نعم",
SetNotification:"تعيين الإعلام",
InternalLMSTrainingCourse:"دورة تدريبية داخلية في LMS",
InternalOfflineTrainingCourse:"دورة تدريبية داخلية دون اتصال بالإنترنت",
ExternalTrainingCourse:"دورة تدريبية خارجية",
InternalTrainingLMS:"التدريب الداخلي LMS",
ListOfInternalTrainingLMS:"قائمة التدريب الداخلي LMS",
ListOfExternalTraining:"قائمة التدريبات الخارجية",
ListOfInternalOfflineTraining:"قائمة التدريب الداخلي دون اتصال",
};
