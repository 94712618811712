export const TRANSLATIONS_EN = {
  MainMenu: "Main Menu",
  home: "home",
  CourseLibrary: "Course Library",
  Training: "Training",
  Profile: "Profile",
  YourAssignment: "Your Assignment",
  Certificates: "Certificates",
  UserReports: "User Reports",
  SearchCourse: "Search Course",
  viewAll: "View all",
  GenerateReport: "Generate Report",
  Enroll: "Enroll",
  CourseContent: "Course Content",
  Material: "Materials",
  Overview: "Overview",
  MyProgress: "My Progress",
  Aboutlession: "Aboutlession",
  Dashboard: "Dashboard",
  LMSCourseBuilder: " LMS Course Builder",
  Groups: "Groups",
  GroupEnrollments: "Group of Enrollments",
  Users: "User",
  ExternalTraining: "External Training",
  InternalOfflineTraining: "Internal Offline Training",
  PreviousTraining: "Previous Training",
  CourseReports: "Reports",
  Enrollments: "Enrollments",
  SubmittedAssignment: "Submitted Assignment",
  Settings: "Settings",
  TrainingEnrollments: "Training Enrollments",
  CompanyName: "Company Name",
  GroupName: "Group Name",
  CourseName: "Course Name",
  FromDate: "From Date",
  ToDate: "To Date",
  SelectCompany: "Select Company",
  SelectGroup: "Select Group",
  SelectCourseName: "Select Course Name",
  SelectDate: "Select Date",
  UserView: "User View",
  Filter: "Filter",
  OverdueEnrollments: "Overdue Enrollments",
  NumberOfOverdueEnrollments: "Number Of Overdue Enrollments",
  ViewAll: "View All",
  UserPassedInThisMonth: "User Passed In This Month",
  UserFailedInThisMonth: "User Failed In This Month",
  NumberOfUserPassed: " Number Of User Passed",
  NumberOfUserFailed: "Number Of User Failed",
  NewEmployees: " New Employees",
  NumberOfNewEmployes: " Number Of New Employes",
  ExpiringTrainingCertificates: "Expiring Training Certificates",
  Reports: "Reports",
  SendReminder: "Send Reminder",
  Show :"Show",
  PagePerItem :"Page Per Item",
  AddGroup:"Add New Group",
  UserList:"List Of User",
};
