import { t } from "i18next";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Navbar from "../Component/NavbarTop";
import Sidebar from "../Component/Sidebar";
import "./Trainings.css";

function TrainingEnrollments() {
  const { clrs } = useSelector((state) => state.createClr);

  return (
    <div className="courselist">
      <Navbar page={t("Enrollments")} />
      <div className="courselist-main">
        <Sidebar page={"enrollments"} hidepage={false} />
        <div className="courselist-main-cnt">
          <div className="admin-dashboard-main-bottom">
            <div className="admin-dashboard-main-bottom-cnt">
              <div className="admin-dashboard-main-bottom-left-icon">
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="50"
                    height="50"
                    rx="12"
                    fill="url(#paint0_linear_134_2559)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_134_2559"
                      x1="-8.38864e-08"
                      y1="24.359"
                      x2="52.7322"
                      y2="24.359"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#007848" />
                      <stop offset="1" stop-color="#004577" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div className="admin-dashboard-main-bottom-right">
                <div className="admin-dashboard-main-bottom-right-top">
                  Internal Training LMS Enrollment
                </div>
                <div className="admin-dashboard-main-bottom-right-btm">
                  List Of Internal Training LMS Enrollments
                </div>
                <div className="admin-dashboard-main-bottom-right-btn">
                  <div className="admin-dashboard-main-bottom-item-left-btm"></div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/internaltraingenrolments"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                          marginLeft: "3vw",
                        }}
                      >
                        <div>View</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="admin-dashboard-main-bottom-cnt">
              <div className="admin-dashboard-main-bottom-left-icon">
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="50"
                    height="50"
                    rx="12"
                    fill="url(#paint0_linear_134_2559)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_134_2559"
                      x1="-8.38864e-08"
                      y1="24.359"
                      x2="52.7322"
                      y2="24.359"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#007848" />
                      <stop offset="1" stop-color="#004577" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div className="admin-dashboard-main-bottom-right">
                <div className="admin-dashboard-main-bottom-right-top">
                  External Training Enrollments
                </div>
                <div className="admin-dashboard-main-bottom-right-btm">
                  List Of External Training Enrollments
                </div>
                <div className="admin-dashboard-main-bottom-right-btn">
                  <div className="admin-dashboard-main-bottom-item-left-btm"></div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/externalTraining"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                          marginLeft: "3vw",
                        }}
                      >
                        <div>View</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="admin-dashboard-main-bottom-cnt">
              <div className="admin-dashboard-main-bottom-left-icon">
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="50"
                    height="50"
                    rx="12"
                    fill="url(#paint0_linear_134_2559)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_134_2559"
                      x1="-8.38864e-08"
                      y1="24.359"
                      x2="52.7322"
                      y2="24.359"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#007848" />
                      <stop offset="1" stop-color="#004577" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div className="admin-dashboard-main-bottom-right">
                <div className="admin-dashboard-main-bottom-right-top">
                  Internal Offline Training Enrollments
                </div>
                <div className="admin-dashboard-main-bottom-right-btm">
                  List Of Internal Offline Training Enrollments
                </div>
                <div className="admin-dashboard-main-bottom-right-btn">
                  <div></div>

                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/internalOfflineTraining"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                          marginLeft: "3vw",
                        }}
                      >
                        <div>View</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrainingEnrollments;
