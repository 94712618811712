export const TRANSLATIONS_EN = {
  MainMenu: "Main Menu",
  Home: "Home",
  CourseLibrary: "Course Library",
  Training: "Training",
  Profile: "Profile",
  YourAssignment: "Your Assignment",
  Certificates: "Certificates",
  UserReports: "User Reports",
  SearchCourse: "Search Course",
  GenerateReport: "Generate Report",
  Enroll: "Enroll",
  CourseContent: "Course Content",
  Material: "Materials",
  Overview: "Overview",
  MyProgress: "My Progress",
  Aboutlession: "Aboutlession",
  quiz:"quiz",
  ExternalTraining: "External Training",
  InternalTraining: "Internal Training",
  DASHBOARD: "DASHBOARD",
  MyCourses: "My Courses",
  ViewAll: "View All",
  FirstAid: "First Aid",
  Description: "Description About The Courses",
  Start: "Start",
  HeatStress: "Heat Strees",
  AddCertificate: "Add Certificate",
  PreviousTraining: "Previous Training",
  CourseName: "Course Name",
  home: "home",
  viewAll: "View all",
  Dashboard: "Dashboard",
  LMSCourseBuilder: " LMS Course Builder",
  Groups: "Groups",
  GroupEnrollments: "Group of Enrollments",
  Users: "User",
  InternalOfflineTraining: "Internal Offline Training",
  CourseReports: "Reports",
  Enrollments: "Enrollments",
  SubmittedAssignment: "Submitted Assignment",
  Settings: "Settings",
  TrainingEnrollments: "Training Enrollments",
  CompanyName: "Company Name",
  GroupName: "Group Name",
  FromDate: "From Date",
  ToDate: "To Date",
  SelectCompany: "Select Company",
  SelectGroup: "Select Group",
  SelectCourseName: "Select Course Name",
  SelectDate: "Select Date",
  UserView: "User View",
  Filter: "Filter",
  OverdueEnrollments: "Overdue Enrollments",
  NumberOfOverdueEnrollments: "Number Of Overdue Enrollments",
  UserPassedInThisMonth: "User Passed This Month",
  UserFailedInThisMonth: "User Failed This Month",
  NumberOfTrainingCertificates:"Number Of Training Certificates",
  NumberOfUserPassed: " Number Of User Passed",
  NumberOfUserFailed: "Number Of User Failed",
  NewEmployees: " New Employees",
  NumberOfNewEmployes: " Number Of New Employes",
  ExpiringTrainingCertificates: "Expiring Training Certificates",
  Reports: "Reports",
  Date :"Date",
  SendReminder: "Send Reminder",
  Show :"Show",
  PagePerItem :"Page Per Item",
  RefNo :"Ref.No",
  TrainingProvider:"Training Provider",
  Back:"Back",
  StartDate :"Start Date",
  CompletionDate:"Completion Date",
  DueOn:"Due On",
  Status:"Status",
  Action:"Action",
  Name:"Name",
  Company:"Company",
  Department:"Department",
  JobTitle:"Job Title",
  JoiningDate:"Joining Date",
  EmployeeNo:"Employee No.",
  Email:"Email",
  Role:"Role",
  Statistics:"Statistics",
  NewEmployeesDetails:"New Employee Details",
  EmployeeID:"Employee ID",
  EmployeeName:"Employee Name",
  TrainingProvider:"Training Provider",
  CertificateName:"Certificate Name",
  Status:"Status",
  Search:"Search",
  ListOfCertificate:"List Of Certificate",
  TypeOfTraining:"Type Of Training",
  IssuedOn:"Issued On",
  Expiry:"Expiry",
  Download:"Download",
  Remind:"Remind",
  Review:"Review",
  Retake:"Retake",
  AddNewGroup:"Add New Group",
  GroupList:"List Of Group",
  GroupNo:"Group No.",
  Name:"Name",
  NumberOfMember:"Number Of Member",
  NoOfCourse:"No. Of Course",
  CreatedOn:"Created On",
  Action:"Action",
  Edit:"Edit",
  Archive:"Archive",
  SRNo:"SR.No",
  AddNewGroupEditGroup:"Add New Group / Edit Group",
  GroupName:"Group Name",
  GroupDescription:"Group Description",
  CreatedBy:"Created By",
  ListOfGroupMember:"List Of Group Member",
  Remove:"Remove",
  EmailID:"Email ID",
  ListOfAllUser:"List Of All User",
  AddInGroup:"Add In Group",
  SaveUpdate:"Save & Update",
  AddGroup:"Add New Group",
UserList:"List Of User",
AddNewUser:"Add New User",
FullName:"Full Name",
Cancel:"Cancel",
Save:"Save",
Instructor:"Instructor",
Admin:"Admin",
User:"User",
AdminIns:"Who can add new users, reset the password, change email address, add to groups.",
UserIns:"who consumes the training material, can generate a report on his training status and upload the previous training that they finished.",
InstructorIns:"who adds, edits, or deletes new courses, generates an assessment and assigns them to users.",
View:"View",
UserName:"User Name",
EmployeeNumber:"Employee Number",
FirstName:"First Name",
LastName:"Last Name",
JoiningDate:"Joining Date",
InternalLMSTraining:"Internal LMS Training",
ExternalTraining:"External Training",
UserDetailsof:"User Details of",
GetCertificate:"Get Certificate",
PreviousTrainingCertificates:"Previous Training Certificates",
Add:"Add",
CertificateExpiry:"Certificate Expiry",
EmployeeEmailID:"Employee Email ID",
CourseTitle:"Course Title",
RefrenceNumber:"Refrence Number",
CourseProvider:"Course Provider",
DateOfIssue:"Date Of Issue",
ExpiryDate:"Expiry Date",
Upload:"Upload",
Delete:"Delete",
Submit:"Submit",
UploadCertificate:"UploadCertificate",
Group:"Group",
ListOfGroupEnrollment:"List Of Group Enrollment",
AddNewGroupEnrollment:"Add New Group Enrollment",
DueDate:"Due Date",
EnrollmentDate:"Enrollment Date",
TypeOftraining:"Type Of training",
AddNewEditGroupEnrollments:"Add New Edit Group Enrollments",
SelectTrainingCourse:"Select Training Course",
Update:"Update",
Members:"Members",
IndividualDueDatesOfUsers:"Individual Due Dates Of Users",
Generate:"Generate",
Completed:"Completed",
ExtraOptionsforInternalOfflineandExternalTrainings:"Extra Options for Internal  Offline and External Trainings",
NotificationFrequency:"Notification Frequency",
No:"No",
Yes:"Yes",
SetNotification:"Set Notification",
InternalLMSTrainingCourse:"Internal LMS Training Course",
InternalOfflineTrainingCourse:"Internal Offline Training Course",
ExternalTrainingCourse:"External Training Course",
InternalTrainingLMS:"Internal Training LMS",
ListOfInternalTrainingLMS:"List Of Internal Training LMS",
ListOfExternalTraining:"List Of External Training",
ListOfInternalOfflineTraining:"List Of Internal Offline Training",
};
