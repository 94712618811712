export const TRANSLATIONS_AR = {
  MainMenu: "القائمة الرئيسية",
  home: "الصفحة الرئيسية",
  CourseLibrary: "مكتبة الدورة",
  Training: "تمرين",
  Profile: "حساب تعريفي",
  YourAssignment: "مهمتك",
  Certificates: "الشهادات",
  UserReports: "تقارير المستخدم",
  SearchCourse: "دورة البحث",
  viewAll: "مشاهدة الكل",
  GenerateReport: "انشاء تقرير",
  Enroll: "يتسجل، يلتحق",
  CourseContent: "محتوى الدورة",
  Material: "المواد",
  Overview: "ملخص",
  MyProgress: "تقدمي",
  Aboutlession: "حول النفاق",
  Dashboard: "لوحة القيادة",
  LMSCourseBuilder: "منشئ دورة LMS",
  Groups: "مجموعات",
  GroupEnrollments: "تسجيلات المجموعة",
  Users: "المستخدمون",
  ExternalTraining: "التدريب الخارجي",
  InternalOfflineTraining: "التدريب الداخلي دون اتصال",
  PreviousTraining: "التدريب السابق",
  CourseReports: "التقارير",
  Enrollments: "التسجيلات",
  SubmittedAssignment: "الواجب المقدم",
  Settings: "إعدادات",
  TrainingEnrollments: "التسجيلات التدريبية",
  CompanyName: "اسم الشركة",
  GroupName: "أسم المجموعة",
  CourseName: "اسم الدورة التدريبية",
  FromDate: "من التاريخ",
  ToDate: "حتى تاريخه",
  SelectCompany: "حدد شركة",
  SelectGroup: "اختر مجموعة",
  SelectCourseName: "حدد اسم المقرر الدراسي",
  SelectDate: "حدد تاريخ",
  UserView: "عرض المستخدم",
  Filter: "منقي",
  OverdueEnrollments: "التسجيلات المتأخرة",
  NumberOfOverdueEnrollments: "عدد التسجيلات المتأخرة",
  ViewAll: "مشاهدة الكل",
  UserPassedInThisMonth: "مر المستخدم هذا الشهر",
  UserFailedInThisMonth: "فشل المستخدم في هذا الشهر",
  NumberOfUserPassed: "عدد المستخدمين المارة",
  NumberOfUserFailed: "عدد المستخدمين الذين فشلوا",
  NewEmployees: "موظفين جدد",
  NumberOfNewEmployes: "عدد الموظفين الجدد",
  ExpiringTrainingCertificates: "انتهاء صلاحية شهادات التدريب",
  Reports: "تقارير",
  SendReminder: "يرسل تذكير",
  Show :"يعرض",
  PagePerItem:"صفحة لكل عنصر",
  AddGroup:"أضف مجموعة جديدة",
  UserList:"قائمة المستخدمين"
};
